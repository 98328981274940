html,
body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    font-family: monospace;
    font-size: 16px;
}

body {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

#container {
    padding: 0.5rem;
    width: min(100%, 50rem);
    display: flex;
    flex-direction: column;
}

#header {
    padding-bottom: 0.5rem;
    display: flex;
    font-size: 0.75rem;
    gap: 1rem;

    :first-child {
        margin-right: auto;
    }

    button {
        margin: 0;
        background: transparent;
        padding: 0;
        border: none;
        font-size: 0.75rem;
        font-family: monospace;
        cursor: pointer;
        text-decoration: underline;
    }
}

#footer {
    padding-top: 0.5rem;
    text-align: center;
    font-size: 0.75rem;
}

#main {
    display: flex;
    border: 1px solid #000;
    flex-grow: 1;
}

#input,
#output {
    padding: 0.5rem;
    border: none;
    overflow-wrap: normal;
    overflow: hidden;
    resize: none;
    &:focus {
        outline: none;
    }
}

#input {
    padding-right: 0;
    width: 80%;
    min-height: 100%;
}

#output {
    padding-left: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    white-space: pre;
    overflow-x: auto;
}

#separator {
    width: calc(1rem + 1px);
    background-color: #aaa;
    cursor: e-resize;
    border-left: 0.5rem solid #fff;
    border-right: 0.5rem solid #fff;
    flex-shrink: 0;
}

#input .cm-content,
#output {
    font-size: 1.1rem;
    font-family: monospace;
    line-height: 1.3;
}

#input .cm-content,
#input .cm-line {
    padding: 0;
}

#input .cm-editor.cm-focused {
    outline: none;
}

#input .cm-editor,
#input .cm-scroller,
#input .cm-content {
    height: 100%;
}

.cm-content .fancaly-literal {
    color: #3a3daa;
}
.cm-content .fancaly-operator {
    color: #c544d4;
}
.cm-content .fancaly-function {
    color: #c544d4;
}
.cm-content .fancaly-unit {
    color: rgb(59 130 246);
}
.cm-content .fancaly-variable {
    color: rgb(16 185 129);
}
