html, body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: monospace;
  font-size: 16px;
}

body {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

*, :before, :after {
  box-sizing: border-box;
}

#container {
  flex-direction: column;
  width: min(100%, 50rem);
  padding: .5rem;
  display: flex;
}

#header {
  gap: 1rem;
  padding-bottom: .5rem;
  font-size: .75rem;
  display: flex;
}

#header :first-child {
  margin-right: auto;
}

#header button {
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-family: monospace;
  font-size: .75rem;
  text-decoration: underline;
}

#footer {
  text-align: center;
  padding-top: .5rem;
  font-size: .75rem;
}

#main {
  border: 1px solid #000;
  flex-grow: 1;
  display: flex;
}

#input, #output {
  overflow-wrap: normal;
  resize: none;
  border: none;
  padding: .5rem;
  overflow: hidden;
}

#input:focus, #output:focus {
  outline: none;
}

#input {
  width: 80%;
  min-height: 100%;
  padding-right: 0;
}

#output {
  white-space: pre;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 0;
  display: flex;
  overflow-x: auto;
}

#separator {
  cursor: e-resize;
  background-color: #aaa;
  border-left: .5rem solid #fff;
  border-right: .5rem solid #fff;
  flex-shrink: 0;
  width: calc(1rem + 1px);
}

#input .cm-content, #output {
  font-family: monospace;
  font-size: 1.1rem;
  line-height: 1.3;
}

#input .cm-content, #input .cm-line {
  padding: 0;
}

#input .cm-editor.cm-focused {
  outline: none;
}

#input .cm-editor, #input .cm-scroller, #input .cm-content {
  height: 100%;
}

.cm-content .fancaly-literal {
  color: #3a3daa;
}

.cm-content .fancaly-operator, .cm-content .fancaly-function {
  color: #c544d4;
}

.cm-content .fancaly-unit {
  color: #3b82f6;
}

.cm-content .fancaly-variable {
  color: #10b981;
}
/*# sourceMappingURL=index.0699760b.css.map */
